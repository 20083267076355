'use client';

import color from '@haaretz/l-color.macro';
import fork from '@haaretz/l-fork.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import radius from '@haaretz/l-radius.macro';
import space from '@haaretz/l-space.macro';
import typesetter from '@haaretz/l-type.macro';
import zIndex from '@haaretz/l-z-index.macro';
import * as React from 'react';
import s9 from 'style9';

import type { ColumnsFragment } from '@haaretz/s-fragments/infographic/Column.infographic';

const c = s9.create({
  base: {
    columnGap: space(3),
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    rowGap: space(4),
    maxWidth: '100%',
    width: '100%',

    ...merge(
      mq({
        from: 'm',
        until: 'l',
        value: { rowGap: space(5) },
      }),
      mq({
        from: 'l',
        value: { rowGap: space(6) },
      })
    ),
  },
  barName: {
    maxWidth: space(30),
    width: '100%',
    alignSelf: 'center',
    ...typesetter(0),

    ...merge(
      mq({
        from: 'm',
        value: { fontWeight: '400' },
      }),
      mq({
        from: 'xxl',
        value: { ...typesetter(-1) },
      })
    ),
  },
  barWrapper: {
    display: 'flex',
    width: '100%',
    transitionDelay: 'var(--transitionDelay)',
    transitionDuration: '300ms',
    transitionProperty: 'transform',
    transitionTimingFunction: 'ease-in',
    transformOrigin: '100% 0',
    alignItems: 'center',
  },
  bar: {
    appearance: 'none',
    border: 'none',
    // @ts-expect-error - we generally want to avoid these kind of things
    '::-webkit-progress-inner-element': {
      visibility: 'hidden',
    },
    '::-moz-progress-bar': {
      visibility: 'hidden',
      backgroundColor: 'transparent',
    },
    alignItems: 'center',
    backgroundColor: color('primary800'),
    display: 'flex',
    gridColumnEnd: 3,
    gridColumnStart: 2,
    height: space(5),
    justifyContent: 'flex-end',
    position: 'relative',
    width: 'var(--barPercentageWidth)',
    minWidth: 'var(--barPercentageWidth)',
    ...merge(
      fork({
        default: {
          borderBottomLeftRadius: radius('pill'),
          borderBottomRightRadius: radius('large'),
          borderTopLeftRadius: radius('pill'),
          borderTopRightRadius: radius('large'),
        },
        hdc: {
          borderBottomLeftRadius: radius('large'),
          borderBottomRightRadius: radius('pill'),
          borderTopLeftRadius: radius('large'),
          borderTopRightRadius: radius('pill'),
        },
      }),
      mq({
        from: 'm',
        value: { height: space(7) },
      })
    ),
  },
  highlightedBar: {
    backgroundColor: color('primary1000'),
  },
  barValue: {
    fontWeight: 700,
    position: 'relative',
    zIndex: zIndex('above'),
    paddingInlineStart: space(2),
    ...typesetter(0),

    ...merge(
      mq({
        from: 'xl',
        until: 'xxl',
        value: { ...typesetter(0) },
      }),
      mq({
        from: 'xxl',
        value: { ...typesetter(-1) },
      })
    ),
  },
  barAnimation: {
    transform: 'scale(1)',
  },

  barMinimized: {
    transform: 'scale(0, 1)',
  },
});

const options: IntersectionObserverInit = {
  root: null,
  rootMargin: '0px',
  threshold: 0.5,
};

interface BarItemsProps {
  bars: ColumnsFragment['items'];
}

export function BarItems({ bars }: Readonly<BarItemsProps>) {
  const observedRef = React.useRef<HTMLDivElement | null>(null);

  const [isInView, setIsInView] = React.useState(false);

  React.useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        setIsInView(true);
        observer.disconnect();
      }
    }, options);

    const curr = observedRef.current;

    if (curr && observer) {
      observer.observe(curr);

      return () => observer.disconnect();
    }

    return () => undefined;
  }, []);

  if (!bars?.length) return null;

  const maxValue = Math.max(...bars.map(bar => bar?.value ?? 0));

  return (
    <div className={s9(c.base)} ref={observedRef}>
      {bars?.map(({ name, color: barColor, unit, value }, index) => {
        const percentageWidth = ((value ?? 0) / maxValue) * 100;
        const spacer = unit && unit.length > 1 ? ' ' : '';
        const valueWithUnit = value && unit ? `${value}${spacer}${unit}` : value ?? '';

        return (
          <React.Fragment key={name ?? index}>
            <label className={s9(c.barName)} htmlFor={`infograpich-itembar-${name}`}>
              {name}
            </label>
            <div
              style={{ '--transitionDelay': `${index * 150}ms` }}
              className={s9(c.barWrapper, c.barMinimized, isInView && c.barAnimation)}
            >
              <progress
                id={`infograpich-itembar-${name}`}
                aria-valuenow={value ?? 0}
                aria-valuemin={0}
                aria-valuemax={maxValue}
                className={s9(c.bar, barColor === 'highlight' && c.highlightedBar)}
                style={{
                  '--barPercentageWidth':
                    value === 0 ? '0' : `max(5px, calc(${percentageWidth}% - 15%))`,
                }}
              />
              <output className={s9(c.barValue)}>{valueWithUnit}</output>
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
}
