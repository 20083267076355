/* eslint-disable import/order, import/no-extraneous-dependencies, @typescript-eslint/no-unused-vars */
// 1. The import order of macros matter and they must be kept in this order
// 2. Since macros are transpiled out during build, it is okay for them
//   to be imported even when they are not used.
// -- color must always be first -- //
import color from '@haaretz/l-color.macro';
// ---
import fontStack from '@haaretz/l-font-stack.macro';
import radius from '@haaretz/l-radius.macro';
import space from '@haaretz/l-space.macro';
import zIndex from '@haaretz/l-z-index.macro';
// --- These return objects and must be spread or used inside `merge` --- //
import border from '@haaretz/l-border.macro';
import shadow from '@haaretz/l-shadow.macro';
import typesetter from '@haaretz/l-type.macro';
// --- These must come last --- //
import fork from '@haaretz/l-fork.macro';
import mq from '@haaretz/l-mq.macro';
import merge from '@haaretz/l-merge.macro';
/* eslint-enable import/order, import/no-extraneous-dependencies, @typescript-eslint/no-unused-vars */

import * as React from 'react';
import s9 from 'style9';

import type { ImageAspect } from '@haaretz/s-fragments/gql-types';
import type { ImageFragment } from '@haaretz/s-fragments/HTZ_image_Image';
import type { StyleExtend, InlineStyles, WithAttrPropsAndAs } from '@haaretz/s-types';

// `c` is short for `classNames`
const c = s9.create({
  caption: {
    color: `var(--captionColorOverride, ${color('neutral1100')})`,
    fontWeight: 700,
    marginTop: space(1),

    ...typesetter(-2),
    ...merge(
      mq({
        from: 'xxl',
        value: {
          ...typesetter(-3),
        },
      })
    ),
  },
  credit: {
    color: `var(--creditColorOverride, ${color('neutral900')})`,
    fontWeight: 400,
    marginInlineStart: space(1),

    ...typesetter(-3),
    ...merge(
      mq({
        from: 'xxl',
        value: {
          ...typesetter(-4),
        },
      })
    ),
  },
  creditVerticalAspect: {
    marginInlineStart: 'initial',
  },
  verticalAspect: {
    display: 'flex',
    flexDirection: 'column',
  },
  padded: {
    paddingInlineStart: space(3),
    paddingInlineEnd: space(3),
  },
});

type AllowedElements = keyof Pick<JSX.IntrinsicElements, 'figcaption' | 'div'>;
export interface CaptionOwnProps<As extends AllowedElements> {
  as?: As;
  /**
   * CSS declarations to be set as inline `style` on the
   * html element.
   *
   * By setting values of CSS Custom Properties based on
   * props or state in the consuming component (where
   * the value of `inlineStyle` is passed), `inlineStyle`
   * can be used as an API contract for setting dynamic
   * values to styles created with `style9.create()`:
   *
   * @example
   * ```ts
   * import s9 from 'style9';
   * const { styleExtend, } = s9.create({
   *   styleExtend: {
   *     color: 'var(--color-based-on-prop)',
   *   },
   * });
   *
   * function MyButton(props) {
   *   const inlineStyle = {
   *     '--color-based-on-prop': props.color,
   *   },
   *
   *   return (
   *    <Button
   *      styleExtend={[ styleExtend, ]}
   *      inlineStyle={inlineStyle}
   *    />
   *   );
   * }
   * ```
   */
  inlineStyle?: InlineStyles;
  /**
   * An array of `Style`s created by `style9.create()`.
   * WARNING: **_do not_** pass simple CSS-in-JS object.
   * The items in the array must be created with Style9's
   * `create` function.
   * The array can also hold falsy values to assist with
   * conditional inclusion of `Style`s:
   *
   * @example
   * ```ts
   * const { foo, bar, } = s9.create({ foo: { ... }, bar: { ... }, });
   * <Button styleExtend={[ someCondition && foo, bar, ]} />
   * ```
   */
  aspect?: ImageAspect;
  styleExtend?: StyleExtend;
  creditStyleExtend?: StyleExtend;
  caption?: ImageFragment['caption'];
  captionPrefix?: string;
  credit?: ImageFragment['credit'];
  padded?: boolean;
}

const DEFAULT_ELEMENT = 'figcaption';
type DefaultElement = typeof DEFAULT_ELEMENT;

export type CaptionProps<As extends AllowedElements = DefaultElement> = Omit<
  WithAttrPropsAndAs<CaptionOwnProps<As>, As>,
  'style'
>;

export default function Caption<As extends AllowedElements = DefaultElement>({
  as,
  aspect,
  caption,
  credit,
  className,
  captionPrefix,
  inlineStyle,
  styleExtend = [],
  creditStyleExtend = [],
  padded,
  ...attrs
}: CaptionProps<As>) {
  if (!(caption || credit)) return null;
  const Element: React.ElementType = as || DEFAULT_ELEMENT;

  const isVertical = aspect === 'vertical';

  const captionText = captionPrefix ? `${captionPrefix}: ${caption}` : caption;
  const s9Classes = s9(
    c.caption,
    padded && c.padded,
    isVertical && c.verticalAspect,
    ...styleExtend
  );

  return (
    <Element
      className={className ? `${className} ${s9Classes}` : s9Classes}
      style={inlineStyle}
      {...attrs}
    >
      {caption ? <span data-testid="caption">{captionText}</span> : null}
      {credit ? (
        <>
          {isVertical ? null : ' '}
          <span
            className={s9(c.credit, isVertical && c.creditVerticalAspect, ...creditStyleExtend)}
            data-testid="credit"
          >
            {credit}
          </span>
        </>
      ) : null}
    </Element>
  );
}
