'use client';

import border from '@haaretz/l-border.macro';
import color from '@haaretz/l-color.macro';
import fork from '@haaretz/l-fork.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import radius from '@haaretz/l-radius.macro';
import space from '@haaretz/l-space.macro';
import typesetter from '@haaretz/l-type.macro';
import Button from '@haaretz/s-button';
import useBi from '@haaretz/s-use-bi';
import * as React from 'react';
import s9 from 'style9';

import useLiveBlog from '@haaretz/s-live-blog/LiveBlogProvider';

import type { LiveBlogItemFragment } from '@haaretz/s-fragments/LiveBlogItem';

const c = s9.create({
  item: {
    position: 'relative',
    display: 'grid',
    overflow: 'visible',
    rowGap: space(3),
  },
  border: {
    paddingBottom: space(4),
    ...border({
      color: color('neutral200'),
      side: 'top',
      style: 'solid',
      width: '4px',
      spacing: 9,
    }),
  },
  content: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    rowGap: space(3),
  },
  hidden: {
    opacity: 0,
    animationName: s9.keyframes({
      '0%': {
        opacity: 0,
        transform: 'translateY(30%)',
      },
      '100%': {
        opacity: 1,
        transform: 'translateY(0)',
      },
    }),
    animationDelay: '0.1s',
    animationDuration: '0.5s',
    animationTimingFunction: 'ease-in-out',
  },
  notExpand: {
    ...merge(mq({ until: 's', value: { maxHeight: space(113), overflow: 'hidden' } })),
  },
  expand: {
    maxHeight: 'auto',
  },
  eclipse: {
    height: '5lh',
    position: 'absolute',
    insetInlineEnd: 0,
    bottom: 0,
    width: '100%',
    backgroundImage: `linear-gradient(to bottom, ${color('contentBg', { opacity: 0 })} 0%, ${color(
      'contentBg'
    )} 100%)` as string,
    ...merge(mq({ from: 's', value: { display: 'none' } })),
  },
  featuredEclipse: {
    backgroundImage: `linear-gradient(to bottom, ${color('neutral300', { opacity: 0 })} 0%, ${color(
      'neutral300'
    )} 100%)` as string,
  },
  featureItem: {
    borderRadius: radius('xLarge'),
    backgroundColor: color('neutral300'),
    paddingTop: space(5),
    paddingBottom: space(5),
    paddingInlineStart: space(2),
    paddingInlineEnd: space(2),
    ...merge(
      mq({ until: 'l', value: { paddingTop: space(5), paddingBottom: space(5) } }),
      mq({
        from: 'l',
        value: {
          paddingInlineStart: space(4),
          paddingInlineEnd: space(4),
          paddingTop: space(6),
          paddingBottom: space(6),
        },
      })
    ),
  },
  button: {
    justifySelf: 'start',
    marginTop: space(4),
    ...typesetter(-2),
    ...merge(mq({ from: 's', value: { display: 'none' } })),
  },
  withoutBorder: {
    paddingTop: space(2),
    paddingBottom: space(2),
    border: 'none',
  },
});

type LiveBlogItemWrapperProps = {
  children: React.ReactNode;
  isNewItem?: boolean;
  itemId: LiveBlogItemFragment['itemId'];
};

const expandButtonText = fork({
  default: 'להרחבה',
  hdc: 'Expand',
});

export default function LiveBlogItemClientWrapper({
  isNewItem,
  children,
  itemId,
}: LiveBlogItemWrapperProps) {
  const state = useLiveBlog();
  const bi = useBi();
  const [open, setIsOpen] = React.useState(false);
  const [availableExpand, setAvailableExpand] = React.useState(false);
  const itemRef = React.useRef<HTMLDivElement | null>(null);
  function onAnimationEnd() {
    if (itemRef.current) {
      itemRef.current.style.opacity = '1';
    }
  }

  const isFeaturedItem = state.featuredItemId === itemId;

  React.useEffect(() => {
    if (itemRef.current?.clientHeight && itemRef.current?.clientHeight > 450) {
      setAvailableExpand(true);
    }
  }, []);

  return (
    <div
      className={s9(
        c.item,
        c.border,
        isFeaturedItem && c.withoutBorder,
        isFeaturedItem && c.featureItem
      )}
      id={itemId ?? undefined}
    >
      <div
        className={s9(
          c.content,
          isNewItem && c.hidden,
          availableExpand && !open ? c.notExpand : c.expand
        )}
        ref={itemRef}
        onAnimationEnd={isNewItem ? onAnimationEnd : () => {}}
      >
        {children}
        {availableExpand && !open && (
          <div className={s9(c.eclipse, isFeaturedItem && c.featuredEclipse)} />
        )}
      </div>
      {availableExpand && (
        <Button
          onClick={() => {
            setIsOpen(true);
            setAvailableExpand(false);
            bi({
              action_id: 169,
              feature_type: 'Content',
              feature: 'Liveblog',
              campaign_name: 'expand item',
              campaign_details: expandButtonText,
            });
          }}
          priority="secondary"
          styleExtend={[c.button]}
        >
          {expandButtonText}
        </Button>
      )}
    </div>
  );
}
