'use client';

import { site } from '@haaretz/s-consts';

export default function FaviconBadge(number: number | null) {
  if (typeof document === 'undefined') return null;

  const canvas = document.createElement('canvas');
  const img = document.createElement('img');
  const favicon = document.querySelector('link[rel="icon"]') as HTMLLinkElement;

  if (!number) {
    clearNumberInTitle();
    favicon.href = `/v2/${site}-bucket/static/favicon.ico`;
  } else {
    img.src = favicon?.href;
    img.onload = function () {
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      if (!ctx) return null;
      ctx.drawImage(img, 0, 0);

      ctx.fillStyle = 'red';
      ctx.beginPath();
      ctx.arc(canvas.width - 5, 6, 4, 0, 2 * Math.PI);
      ctx.fill();

      favicon.href = canvas.toDataURL();
      setNumberInTitle(number);
      return '';
    };
  }

  return null;
}

const regex = /^\(\d*\) /;
function alreadyHasNumberInTitle(docTitle: string) {
  return docTitle.match(regex);
}

function updateNumberInTitle(number: number) {
  if (document) {
    document.title = document.title.replace(regex, `(${number}) `);
  }
}

export function setNumberInTitle(number: number) {
  if (alreadyHasNumberInTitle(document.title)) {
    updateNumberInTitle(number);
  } else if (document) {
    document.title = `(${number}) ${document.title}`; // new number
  }
}

export function clearNumberInTitle() {
  if (document) {
    document.title = document.title.replace(regex, '');
  }
}
