'use client';

import useOnce from '@haaretz/s-use-once';

interface ScrollToFeatureItemProps {
  featureItemId: string;
}

export default function ScrollToFeatureItem({ featureItemId }: ScrollToFeatureItemProps) {
  useOnce(() => {
    const featureItem = document.getElementById(featureItemId);

    if (featureItem) {
      featureItem.scrollIntoView({ behavior: 'smooth' });

      if (typeof googletag !== 'undefined') {
        googletag?.pubads?.().addEventListener('slotRenderEnded', function ({ slot }) {
          const adUnitPath = slot.getAdUnitPath();

          if (adUnitPath.includes('billboard') || adUnitPath.includes('interstitial')) {
            featureItem.scrollIntoView({ behavior: 'smooth' });
          }
        });
      }
    }

    return undefined;
  }, !!featureItemId);

  return null;
}
