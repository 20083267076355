'use client';

import color from '@haaretz/l-color.macro';
import fork from '@haaretz/l-fork.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import space from '@haaretz/l-space.macro';
import typesetter from '@haaretz/l-type.macro';
import AlertsSubscriptionPopover from '@haaretz/s-alerts-subscription-popover';
import usePlatform from '@haaretz/s-atoms/platform';
import useUser from '@haaretz/s-atoms/user';
import Chip from '@haaretz/s-chip';
import useClientQuery from '@haaretz/s-client-data-hooks/useClientQuery';
import { site } from '@haaretz/s-consts';
import ErrorBoundary from '@haaretz/s-error-boundary';
import { useUserAlertsRegistrationPersonalMutation } from '@haaretz/s-mutations/UserAlertsRegistration';
import useBi from '@haaretz/s-use-bi';
import useDocumentEventListener from '@haaretz/s-use-native-application/useDocumentEventListener';
import * as React from 'react';
import s9 from 'style9';

import type { IconProps } from '@haaretz/s-icon';

type Icon = IconProps['icon'];

// `c` is short for `classNames`
const c = s9.create({
  base: {
    marginInlineStart: space(1),
    '--borderColor': color('primary1000', { opacity: 0.2 }),
    '--pressed-brdr-clr': color('contentBg'),
    color: color('primary1000'),
    ...typesetter(-2),
    ...merge(
      mq({
        from: 's',
        until: 'xl',
        value: {
          ...typesetter(-1),
        },
      }),
      mq({
        from: 'xl',
        until: 'xxl',
        value: {
          ...typesetter(-2),
        },
      }),
      mq({
        from: 'xxl',
        value: {
          ...typesetter(-3),
        },
      })
    ),
  },
  pressed: {
    '--pressed-bg-clor': color('contentBg'),
    '--borderColor': color('neutral400'),
    color: color('neutral1000'),
  },
  wrraperForOnboarding: {
    width: space(18),
    display: 'inline',
  },
});

export interface AlertsRegistrationButtonProps {
  /** contentId for the author */
  contentId: string;
  /** author name  for the alerts registration*/
  authorName: string;
  /** for UI management */
  withDateModified?: boolean;
  /** resourceType for the author */
  resourceType: 'writer' | 'blog';
}

const btnTexts = {
  signup: fork({ default: 'לעקוב', hdc: 'Sign up' }),
  unsubscribe: fork({ default: 'להסיר', hdc: 'Unsubscribe' }),
} as const;

interface IsSubscribed {
  subscribed?: boolean;
}

type BtnKind = keyof typeof btnTexts;

function AlertsRegistrationButton({
  contentId,
  authorName,
  resourceType,
}: AlertsRegistrationButtonProps) {
  const platform = usePlatform();
  const user = useUser('serverValue');
  const [openPopover, setOpenPopover] = React.useState<boolean>(false);
  const biAction = useBi();
  const followBtnRef = React.useRef(null);

  const isApp = platform === 'app';

  const { data: userSubscriptionData } = useClientQuery<IsSubscribed>({
    url: 'https://services.haaretz.co.il/user-alerts/is-subscribed',
    fetchOptions: { method: 'GET' },
    variables: { resourceId: contentId, resourceType, site, email: user?.userMail },
    cacheKey: 'writerAlerts',
    clientOptions: { enabled: !!user.userMail },
  });

  const [btnKind, setBtnKind] = React.useState<BtnKind>('signup');
  React.useEffect(() => {
    if (userSubscriptionData?.subscribed) {
      setBtnKind('unsubscribe');
    }
  }, [userSubscriptionData]);

  const [status, setStatus] = React.useState(isApp ? 'Unfollow' : '');

  const setSubscribeBtn = () => {
    setBtnKind('signup');
  };

  const setUnsubscribeBtn = () => {
    setBtnKind('unsubscribe');
  };

  const onUnfollow = React.useCallback(() => {
    if (status !== 'Unfollow') {
      setStatus('Unfollow');
      setUnsubscribeBtn();
    }
  }, [status]);

  const onFollow = React.useCallback(() => {
    if (status !== 'Follow') {
      setStatus('Follow');
      setSubscribeBtn();
    }
  }, [status]);

  // NOTE: In application we use `setFollow` and `setUnfollow` events to follow up the status of the author,
  // but historically the sence of the events is opposite to the name of the event.
  useDocumentEventListener('setFollow', onUnfollow, false);
  useDocumentEventListener('setUnfollow', onFollow, false);

  const handleOpenPopoverStateChangeHandler = (boolean: boolean) => {
    setOpenPopover(boolean);
  };

  const action = btnKind === 'signup' ? 'subscribe' : 'unsubscribe';

  const userAlertsMutation = useUserAlertsRegistrationPersonalMutation({
    onSuccess: () => {
      setBtnKind(btnKind === 'signup' ? 'unsubscribe' : 'signup');
    },
  });

  const sharedBiData = {
    campaign_name: `follow ${resourceType}`,
    feature: `${resourceType === 'writer' ? 'Writer' : 'Blog'} Alert - article page`,
    feature_type: 'Content',
    newsletter_segment_id: contentId,
    newsletter_segment_name: authorName,
  };

  function anonymousFollowBtnClick() {
    biAction({
      action_id: 91,
      campaign_details: 'open UI',
      ...sharedBiData,
    });
  }

  async function onFollowBtnClick() {
    if (action === 'subscribe') {
      if (!isApp) {
        biAction({
          action_id: 9,
          campaign_details: 'follow',
          newsletter_email: user.userMail,
          ...sharedBiData,
        });
        userAlertsMutation.mutate({
          input: {
            isUnsubscribe: false,
            site,
            resourceType,
            resourceId: contentId,
          },
        });
      }
    }
    if (action === 'unsubscribe') {
      if (!isApp) {
        userAlertsMutation.mutate({
          input: {
            isUnsubscribe: true,
            site,
            resourceType,
            resourceId: contentId,
          },
        });
      }
      biAction({
        action_id: 8,
        campaign_details: 'unfollow',
        newsletter_email: user.userMail,
        ...sharedBiData,
      });
    }
  }

  const isFollow = status === 'Follow';

  return (
    <>
      <Chip
        ref={followBtnRef}
        as={isApp ? 'a' : 'button'}
        styleExtend={[c.base, btnKind === 'unsubscribe' ? c.pressed : null]}
        isPressable
        isPressed={btnKind === 'unsubscribe'}
        pressableIcons={{ pressed: 'bell-off' as Icon, unpressed: 'bell' as Icon }}
        {...(user.userType !== 'anonymous'
          ? { onClick: onFollowBtnClick }
          : { onClick: anonymousFollowBtnClick })}
        {...(isApp
          ? {
              href: `https://author.alerts.${isFollow ? 'off' : 'on'}/`,
              target: '_blank',
              rel: 'noreferrer',
            }
          : {})}
      >
        {btnTexts[btnKind]}
      </Chip>
      {!isApp && user.userType === 'anonymous' ? (
        <AlertsSubscriptionPopover
          setUnsubscribeButton={setUnsubscribeBtn}
          isOpenFromParent={openPopover}
          contentId={contentId}
          alertType={resourceType}
          onToggle={handleOpenPopoverStateChangeHandler}
          refersToRef={followBtnRef}
          popoverPlacement={'bottom-start'}
          authorName={authorName}
        />
      ) : null}
    </>
  );
}

function ChipFallback() {
  return (
    <Chip
      styleExtend={[c.base]}
      isPressable
      pressableIcons={{ pressed: 'bell-off' as Icon, unpressed: 'bell' as Icon }}
    >
      לעקוב
    </Chip>
  );
}

export default function AlertsRegistrationButtonWrapper(props: AlertsRegistrationButtonProps) {
  return (
    <ErrorBoundary>
      <div id="btn-author-alerts" className={s9(c.wrraperForOnboarding)}>
        <React.Suspense fallback={<ChipFallback />}>
          <AlertsRegistrationButton {...props} />
        </React.Suspense>
      </div>
    </ErrorBoundary>
  );
}
