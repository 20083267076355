import breakUrl from '../breakUrl';

function getArticleIdFromURL(url: string) {
  if (!url) return null;

  const { pathname } = breakUrl(url);

  const regex = /[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}$/;
  const result = regex.test(pathname) ? pathname.match(regex)?.[0] : null;

  return result || null;
}

export default getArticleIdFromURL;
