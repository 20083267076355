'use client';

import { useCookieAtom } from '@haaretz/s-atoms/cookie';
import setCookie from '@haaretz/s-cookie-utils/setCookie';
import { usePathname } from 'next/navigation';
import React from 'react';

export default function LiveBlogCookieCounter() {
  const [cookie, updateCookie] = useCookieAtom('cookieValue');
  const pathname = usePathname();

  const lbcounterCookieRef = React.useRef(cookie.lbcounter);

  React.useEffect(() => {
    lbcounterCookieRef.current = cookie.lbcounter;
  }, [cookie.lbcounter]);

  React.useEffect(() => {
    const now = new Date();
    const endOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999);

    const newCounter = +(lbcounterCookieRef.current || 0) + 1;
    setCookie('lbcounter', newCounter.toString(), endOfDay);
    updateCookie(document.cookie);
  }, [updateCookie, pathname]);

  return null;
}
