import discardNullableValues from '@haaretz/s-common-utils/discardNullableValues';
import throwError from '@haaretz/s-common-utils/throwError';
import { LIVE_BLOG_SCROLL_TO_ITEM_EVENT } from '@haaretz/s-consts';
import { BiFunction } from '@haaretz/s-use-bi';

import loadMoreLiveBlogItems from '../utils/loadMoreLiveBlogItems';

import type { LiveBlogAction, LiveBlogState } from '../types/liveBlogState.types';

export async function changeOrderAction(
  dispatch: React.Dispatch<LiveBlogAction>,
  order: LiveBlogState['order'],
  lastItemId?: LiveBlogState['lastItemId']
) {
  dispatch({ type: 'CHANGE_ORDER_REQUEST' });

  try {
    const args = discardNullableValues<Parameters<typeof loadMoreLiveBlogItems>[0]>({
      lastItemId,
      area: 'bottom',
      postCacheParams: 'sa-live-blog-items',
      sort: order,
      count: 10,
    });
    const response = await loadMoreLiveBlogItems(args);

    dispatch({
      type: 'CHANGE_ORDER_SUCCESS',
      payload: {
        firstItemId: response.firstItemId ?? undefined,
        lastItemId: response.lastItemId ?? undefined,
        order,
        items: response.html,
      },
    });
  } catch (error) {
    throwError(error as Error);
    dispatch({ type: 'CHANGE_ORDER_FAILURE' });
  }
}

export async function loadItemsBelowAction(
  dispatch: React.Dispatch<LiveBlogAction>,
  {
    lastItemId,
    order,
    clickedItemId,
  }: {
    order: LiveBlogState['order'];
    lastItemId: LiveBlogState['lastItemId'];
    clickedItemId?: string;
  },
  biAction: BiFunction
) {
  try {
    dispatch({ type: 'LOAD_ITEMS_BELOW_REQUEST' });
    const args = discardNullableValues<Parameters<typeof loadMoreLiveBlogItems>[0]>({
      lastItemId,
      area: 'bottom',
      postCacheParams: 'sa-live-blog-items',
      sort: order,
    });
    const response = await loadMoreLiveBlogItems(args);

    if (response.html?.length) {
      dispatch({
        type: 'LOAD_ITEMS_BELOW_SUCCESS',
        payload: response.html,
      });

      if (clickedItemId) {
        const scrollEvent: CustomEvent<string> = new CustomEvent(LIVE_BLOG_SCROLL_TO_ITEM_EVENT, {
          detail: clickedItemId,
        });

        document.dispatchEvent(scrollEvent);
      }
    } else {
      dispatch({
        type: 'LOAD_ITEMS_BELOW_SUCCESS',
        payload: [],
      });
      // setShowMoreItems(true);
    }
  } catch (error) {
    dispatch({ type: 'LOAD_ITEMS_BELOW_REQUEST' });
    throwError(error as Error);
  } finally {
    biAction({
      action_id: 148,
      feature_type: 'Content',
      feature: 'more live blog items',
      campaign_name: 'load all', // We are always loading all now
    });
  }
}

export function showItemsBelowAction(dispatch: React.Dispatch<LiveBlogAction>) {
  dispatch({ type: 'SHOW_ITEMS_BELOW' });
}

export async function loadItemsAboveAction(
  dispatch: React.Dispatch<LiveBlogAction>,
  {
    firstItemId,
    order,
  }: {
    order: LiveBlogState['order'];
    firstItemId: LiveBlogState['firstItemId'];
  },
  biAction: BiFunction
) {
  try {
    dispatch({ type: 'LOAD_ITEMS_ABOVE_REQUEST' });

    const args = discardNullableValues<Parameters<typeof loadMoreLiveBlogItems>[0]>({
      lastItemId: firstItemId,
      area: 'top',
      postCacheParams: 'sa-live-blog-items',
      sort: order,
    });
    const response = await loadMoreLiveBlogItems(args);

    dispatch({
      type: 'LOAD_ITEMS_ABOVE_SUCCESS',
      payload: {
        firstItemId: response.firstItemId ?? undefined,
        items: response.html,
      },
    });
  } catch (error) {
    dispatch({ type: 'LOAD_ITEMS_ABOVE_FAILURE' });
    throwError(error as Error);
  } finally {
    biAction({
      action_id: 148,
      feature_type: 'Content',
      feature: 'more live blog items',
      campaign_name: 'load all',
    });
  }
}

export function showItemsAboveAction(dispatch: React.Dispatch<LiveBlogAction>) {
  dispatch({ type: 'SHOW_ITEMS_ABOVE' });
}

export function setItemIdAction(
  dispatch: React.Dispatch<LiveBlogAction>,
  type: 'FIRST' | 'LAST' | 'FEATURED',
  itemId: NonNullable<LiveBlogState['firstItemId']>
) {
  dispatch({ type: `SET_${type}_ITEM_ID`, payload: itemId });
}

export function removeItemIdAction(
  dispatch: React.Dispatch<LiveBlogAction>,
  type: 'FIRST' | 'LAST' | 'FEATURED'
) {
  dispatch({ type: `REMOVE_${type}_ITEM_ID` });
}

export function clearItemsAction(dispatch: React.Dispatch<LiveBlogAction>) {
  dispatch({ type: 'CLEAR_ITEMS' });
}
