'use client';

import { useEffect } from 'react';

type GptTargetingValue = string | string[];

interface GptTargetingEntry {
  key: string;
  value: GptTargetingValue | (() => GptTargetingValue);
}

interface GptTargetingProps {
  targeting: GptTargetingEntry | GptTargetingEntry[];
}

export default function GptTargeting({ targeting }: GptTargetingProps) {
  useEffect(() => {
    window.googletag = window.googletag || { cmd: [] };
    const targetingArray = Array.isArray(targeting) ? targeting : [targeting];

    targetingArray.forEach(({ key, value }) => {
      const isValueGenerator = typeof value === 'function';
      const targetingValue = isValueGenerator ? value() : value;

      window.googletag.cmd.push(() => {
        googletag.pubads().setTargeting(key, targetingValue);
      });
    });

    return () => {
      window.googletag.cmd.push(() => {
        targetingArray.forEach(({ key }) => {
          googletag.pubads().clearTargeting(key);
        });
      });
    };
  }, [targeting]);

  return null;
}
