import type { LiveBlogState } from '../types/liveBlogState.types';

interface LiveBlogStateArgs {
  featuredItemId?: LiveBlogState['featuredItemId'];
}

export default function createLiveBlogState({ featuredItemId }: LiveBlogStateArgs): LiveBlogState {
  return {
    itemsAboveLoading: false,
    itemsAbovePreloaded: [],
    itemsAbove: [],
    itemsLoading: false,
    items: [],
    itemsBelowLoading: false,
    itemsBelow: [],
    showItemsBelow: false,
    order: 'desc',
    featuredItemId: featuredItemId ?? null,
  };
}
