'use client';

import color from '@haaretz/l-color.macro';
import fork from '@haaretz/l-fork.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import space from '@haaretz/l-space.macro';
import typesetter from '@haaretz/l-type.macro';
import SortDropdown from '@haaretz/s-dropdown';
import useBi from '@haaretz/s-use-bi';
import * as React from 'react';
import s9 from 'style9';

import useLiveBlog, { useLiveBlogActions } from '../LiveBlogProvider';

import type { LiveBlogItemsSort } from '@haaretz/s-fragments/Types';
const c = s9.create({
  base: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    paddingTop: space(3),
    ...merge(
      mq({
        from: 's',
        until: 'l',
        value: {
          paddingTop: space(8),
          paddingBottom: space(7),
        },
      }),
      mq({
        from: 'l',
        value: {
          paddingTop: 0,
        },
      })
    ),
  },
  dropdown: {
    columnGap: space(1),
    ...typesetter(-2),
    ...merge(
      mq({
        from: 's',
        until: 'xxl',
        value: {
          ...typesetter(0),
        },
      })
    ),
  },
  title: {
    alignSelf: 'center',
    ...typesetter(1),
    ...merge(
      mq({
        from: 's',
        until: 'l',
        value: {
          ...typesetter(2),
        },
      }),
      mq({
        from: 'xxl',
        value: {
          ...typesetter(0),
        },
      })
    ),
  },
  dropdownIcon: {
    fontSize: space(4),
  },
  fromFirstToLastIcon: {
    transform: 'rotateX(180deg)',
  },
  dropdownBtn: {
    columnGap: space(1),
    paddingInlineStart: space(3),
    paddingInlineEnd: space(3),
    ...merge(
      mq({
        from: 's',
        value: {
          columnGap: space(2),
          paddingInlineEnd: space(4),
          paddingInlineStart: space(4),
        },
      })
    ),
  },
  selectedOption: {
    fontWeight: 700,
  },
  option: {
    paddingInlineStart: space(3),
    paddingInlineEnd: space(3),
    ...merge(
      mq({
        from: 's',
        value: {
          paddingInlineStart: space(4),
          paddingInlineEnd: space(4),
        },
      })
    ),
  },
  withBottomStrip: {
    paddingBottom: space(3),
    borderBottomStyle: 'solid',
    borderBottomWidth: space(1),
    borderBottomColor: color('neutral200'),
  },
});

const options: { text: string; value: LiveBlogItemsSort }[] = [
  { text: fork({ default: 'מהאחרון לראשון', hdc: 'Last to first' }), value: 'desc' },
  { text: fork({ default: 'מהראשון לאחרון', hdc: 'First to last' }), value: 'asc' },
] as const;

export default function LiveBlogMenu() {
  const biAction = useBi();
  const { changeOrder } = useLiveBlogActions();
  const state = useLiveBlog();

  const shouldRenderDropdown = !state.featuredItemId;

  return (
    <div className={s9(c.base, !shouldRenderDropdown && c.withBottomStrip)}>
      <h2 className={s9(c.title)}>{fork({ default: 'עדכונים', hdc: 'Updates' })}</h2>
      {shouldRenderDropdown ? (
        <SortDropdown
          options={options}
          label=""
          initialSelectedOption={0}
          dropdownIcon="sort"
          hideCheckIconOnSelectedOption
          dropdownStyleExtend={[c.dropdown]}
          variant="neutral"
          dropdownIconStyleExtend={[
            c.dropdownIcon,
            state.order === 'asc' ? c.fromFirstToLastIcon : null,
          ]}
          selectStyleExtend={[c.dropdownBtn]}
          actualSelectedOptionStyleExtend={[c.selectedOption]}
          optionStyleExtend={[c.option]}
          onChange={option => {
            changeOrder(options[option ?? 0].value || 'desc');

            biAction({
              action_id: 104,
              feature_type: 'Content',
              feature: 'Sort live blog',
              campaign_name: state.order === 'desc' ? 'Date Descending' : 'Date Ascending',
            });
          }}
        />
      ) : null}
    </div>
  );
}
