'use client';
/* eslint-disable import/order, import/no-extraneous-dependencies, @typescript-eslint/no-unused-vars */
// 1. The import order of macros matter and they must be kept in this order
// 2. Since macros are transpiled out during build, it is okay for them
//   to be imported even when they are not used.
// -- color must always be first -- //
import color from '@haaretz/l-color.macro';
// ---
import radius from '@haaretz/l-radius.macro';
import space from '@haaretz/l-space.macro';
// --- These return objects and must be spread or used inside `merge` --- //
// --- These must come last --- //
import fork from '@haaretz/l-fork.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
/* eslint-enable import/order, import/no-extraneous-dependencies, @typescript-eslint/no-unused-vars */
import usePlatform from '@haaretz/s-atoms/platform';
import ClickArea from '@haaretz/s-click-area';
import { BeforeAndAfterFragment } from '@haaretz/s-fragments/BeforeAndAfter';
import Icon from '@haaretz/s-icon';
import * as React from 'react';
import s9 from 'style9';

// `c` is short for `classNames`
const c = s9.create({
  imagesContainer: {
    display: 'grid',
    gridTemplateColumns: fork({
      default: 'var(--splitPosition) minmax(0, 1fr)',
      hdc: 'minmax(0, 1fr) var(--splitPosition)',
    }),
    overflow: 'hidden',
  },
  beforeImgContainer: {
    gridColumnEnd: -1,
    gridColumnStart: 1,
    gridRowStart: 1,
  },
  imgContainer: {
    height: '100%',
    width: '100%',
    overflow: 'hidden',
  },
  img: {
    height: '100%',
    position: 'relative',
    objectFit: 'cover',
    objectPosition: fork({
      default: 'right',
      hdc: 'left',
    }),
    userSelect: 'none',
  },
  divider: {
    position: 'relative',
    gridColumnStart: 2,
    gridColumnEnd: 3,
    gridRowStart: 1,
    height: '100%',
    width: space(1),
    zIndex: 10,
    backgroundColor: color('neutral400'),
  },
  clickArea: {
    '--size': space(15),
    bottom: 0,
    cursor: 'grab',
    aspectRatio: '1',
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    left: '50%',
    transform: 'translateX(-50%)',

    ':active': {
      cursor: 'grabbing',
      transform: 'translateX(-50%) scale(0.95)',
    },

    ...merge(
      mq({
        from: 's',
        until: 'l',
        value: {
          '--size': space(17),
          bottom: 0,
        },
      }),
      mq({
        from: 'l',
        until: 'xl',
        value: {
          '--size': space(17),
          bottom: space(1),
        },
      }),
      mq({
        from: 'xl',
        until: 'xxl',
        value: {
          '--size': space(22),
          bottom: space(2),
        },
      }),
      mq({
        from: 'xxl',
        value: {
          bottom: space(3),
          '--size': space(24),
        },
      })
    ),
  },
  button: {
    display: 'flex',
    flexDirection: fork({ default: 'row', hdc: 'row-reverse' }),
    alignItems: 'center',
    justifyContent: 'center',
    border: `2px solid ${color('neutral300')}`,
    width: space(8),
    aspectRatio: '1',
    backgroundColor: color('neutral150'),
    borderRadius: radius('circle'),

    ...merge(
      mq({
        from: 's',
        until: 'l',
        value: {
          maxWidth: space(10),
        },
      }),
      mq({
        from: 'xl',
        until: 'xxl',
        value: {
          width: space(13),
        },
      }),
      mq({
        from: 'xxl',
        value: {
          width: space(16),
        },
      })
    ),
  },
  chevronLeft: {
    transform: 'rotate(180deg)',
  },
  afterImgContainer: {
    gridColumnStart: 1,
    zIndex: 1,
    gridRowStart: 1,
  },
  chevron: {
    fontSize: space(5),
    color: color('neutral900'),
    ...merge(
      mq({
        from: 'xxl',
        value: {
          fontSize: space(7),
        },
      })
    ),
  },
});

export interface BeforeAndAfterClientProps {
  linePosition: BeforeAndAfterFragment['linePosition'];
  afterImage: React.ReactNode;
  beforeImage: React.ReactNode;
}
type Percentage = `${number}%`;

export default function BeforeAndAfterClient({
  linePosition = '50%',
  beforeImage,
  afterImage,
}: BeforeAndAfterClientProps) {
  const platform = usePlatform();
  const [splitPosition, setSplitPosition] = React.useState<string | Percentage>(
    linePosition as Percentage
  );
  const imagesContainerRef = React.useRef<null | HTMLDivElement>(null);
  const validateSplitPosition = (userSplitPosition: number): number => {
    if (userSplitPosition > 100) {
      // This is not 100 because the divider is 4px wide and we want the devider to stay visible
      return 99.6;
    } else if (userSplitPosition < 0) {
      return 0;
    } else return userSplitPosition;
  };

  const slide = React.useCallback((xPosition: number): void => {
    const container = imagesContainerRef.current;

    if (container) {
      const imagesContainerRect = container?.getBoundingClientRect();
      const userRevealCalc =
        ((imagesContainerRect.right - xPosition) / imagesContainerRect.width) * 100;

      const newsplitPosition = `${validateSplitPosition(userRevealCalc)}%`;

      setSplitPosition(newsplitPosition);
    }
  }, []);

  /**
   * `handleTouchStart` and `handleTouchEnd` prevent swiping between articles when in the page is rendered in the native apps
   * */

  const handleTouchStart = () => {
    if (platform === 'app' && typeof window.HtmlViewer?.disablePaging === 'function') {
      window.HtmlViewer.disablePaging();
    }
  };

  const handleTouchEnd = () => {
    if (platform === 'app' && typeof window.HtmlViewer?.enablePaging === 'function') {
      window.HtmlViewer.enablePaging();
    }
  };

  const handleTouchMove = (event: React.TouchEvent<HTMLButtonElement>) => {
    if (event.touches.item(0)?.clientX !== undefined) {
      slide(event.touches.item(0)?.clientX);
    }
  };

  const handleKeyDown: React.KeyboardEventHandler = React.useCallback(
    evt => {
      let newLinePosition = parseFloat(splitPosition);
      if (evt.code === 'ArrowLeft') {
        newLinePosition += 2;
      }
      if (evt.code === 'ArrowRight') {
        newLinePosition -= 2;
      }
      setSplitPosition(`${validateSplitPosition(newLinePosition)}%`);
    },
    [splitPosition]
  );

  const handleMouseMove = (evt: MouseEvent) => {
    slide(evt.clientX);
  };
  const handleMouseUp = () => {
    window.onmousemove = null;
    window.onmouseup = null;
  };

  const handleMouseDown = () => {
    window.onmousemove = handleMouseMove;
    window.onmouseup = handleMouseUp;
  };

  return (
    <div
      className={s9(c.imagesContainer)}
      ref={imagesContainerRef}
      data-testid="imagesContainer"
      style={
        {
          '--splitPosition': `${splitPosition}`,
        } as React.CSSProperties
      }
    >
      <div className={s9(c.imgContainer, c.beforeImgContainer)} data-testid="beforeImageContainer">
        {beforeImage}
      </div>
      <div className={s9(c.divider)}>
        <ClickArea
          size="large"
          rippleSize="small"
          onMouseDown={handleMouseDown}
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
          onTouchMove={handleTouchMove}
          onKeyDown={handleKeyDown}
          styleExtend={[c.clickArea]}
        >
          <div className={s9(c.button)}>
            <Icon icon="chevron" styleExtend={[c.chevron]} />
            <Icon icon="chevron" styleExtend={[c.chevronLeft, c.chevron]} />
          </div>
        </ClickArea>
      </div>
      <div className={s9(c.imgContainer, c.afterImgContainer)} data-testid="afterImageContainer">
        {afterImage}
      </div>
    </div>
  );
}
