import config from '@haaretz/l-config';
import { useMutation } from '@tanstack/react-query';

const urlPrefix = config.get('brightspot');

type InterestAction = 'addInterest' | 'removeInterest';
const buildInterestPath = (action: InterestAction, authorId: string) =>
  `${urlPrefix}/srv/siteUser?action=${action}&authorId=${authorId}`;

interface InterestChipAction {
  action: InterestAction;
  authorId: string;
}

interface InterestChipResponse {
  site: 'haaretz';
  status: string;
  version: string;
}

export default function useInterestChipActionMutation({
  variables,
}: {
  variables: InterestChipAction;
}) {
  const url = buildInterestPath(variables.action, variables.authorId);

  return useMutation<InterestChipResponse, Error>({
    mutationKey: [`author-interest-${variables.action}`, url],
    mutationFn: async () => {
      try {
        const res = await fetch(url, {
          method: 'GET',
          credentials: 'include',
        });
        const interestRegistrationRes: InterestChipResponse = await res.json();

        return interestRegistrationRes;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    useErrorBoundary: false,
    cacheTime: 0,
    retry: false,
  });
}
