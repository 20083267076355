'use client';
/* eslint-disable import/order, import/no-extraneous-dependencies, @typescript-eslint/no-unused-vars */
// 1. The import order of macros matter and they must be kept in this order
// 2. Since macros are transpiled out during build, it is okay for them
//   to be imported even when they are not used.
// -- color must always be first -- //
import color from '@haaretz/l-color.macro';
// ---
import fontStack from '@haaretz/l-font-stack.macro';
import radius from '@haaretz/l-radius.macro';
import space from '@haaretz/l-space.macro';
// --- These return objects and must be spread or used inside `merge` --- //
import border from '@haaretz/l-border.macro';
import typesetter from '@haaretz/l-type.macro';
// --- These must come last --- //
import fork from '@haaretz/l-fork.macro';
/* eslint-enable import/order, import/no-extraneous-dependencies, @typescript-eslint/no-unused-vars */

import Icon from '@haaretz/s-icon';
import VisuallyHidden from '@haaretz/s-visually-hidden';
import * as React from 'react';
import s9 from 'style9';

import type { IconProps } from '@haaretz/s-icon';
import type {
  InlineStyles,
  PolymorphicForwardRefExoticComponent,
  PolymorphicPropsWithRef,
  StyleExtend,
} from '@haaretz/s-types';

const iconAnimationTime = 0.35;
const colorTransitionTime = '0.2s';
const outlineTransitionTime = '0.1s';

// `c` is short for `classNames`
const c = s9.create({
  base: {
    '--defaultBorderColor': color('neutral400'),
    alignItems: 'center',
    backgroundColor: color('contentBg'),
    borderRadius: radius('pill'),
    color: color('neutral1000'),
    columnGap: space(1),
    display: 'inline-flex',
    fontFamily: fork({
      default: fontStack('primary'),
      hdc: fontStack('secondary'),
    }),
    outlineStyle: 'solid',
    outlineOffset: 0,
    outlineWidth: 0,
    outlineColor: 'var(--borderColor, var(--defaultBorderColor))',
    overflow: 'hidden',
    transitionProperty: 'background-color, border-color, outline-offset, outline-width',
    transitionDuration: `${
      // background-color
      colorTransitionTime
    }, ${
      // border-color
      colorTransitionTime
    }, ${
      // outline-ofset
      outlineTransitionTime
    }, ${
      // outline-width
      outlineTransitionTime
    }`,
    transitionTimingFunction: 'ease-in-out',
    whiteSpace: 'nowrap',

    ...typesetter(-2),
    ...border({
      width: '1px',
      style: 'solid',
      color: 'var(--borderColor, var(--defaultBorderColor))',
      spacing: 1,
      side: 'block',
    }),
    ...border({
      width: '1px',
      style: 'solid',
      color: 'var(--borderColor, var(--defaultBorderColor))',
      spacing: 3,
      side: 'inline',
    }),

    ':hover': {
      backgroundColor: color('primary200'),
    },
    ':focus-visible': {
      outlineWidth: '2px',
      outlineOffset: '6px',
    },
    ':active': {
      transform: 'scale(0.95)',
    },
  },
  liveBlogVariant: {
    backgroundColor: color('neutral200'),
    color: color('primary1000'),
    '--borderColor': color('neutral300'),
    borderWidth: space(0.5),

    ':hover': {
      backgroundColor: color('neutral300'),
      '--borderColor': color('neutral500'),
    },
  },
  isPressed: {
    '--borderColor': `var(--pressed-brdr-clr, ${color('chipPressedBorder')})`,
    backgroundColor: `var(--pressed-bg-clor, ${color('chipPressedBg')})`,

    ':hover': {
      backgroundColor: color('primary400'),
    },
  },
  icon: {
    marginInlineStart: space(-1),
    animationDirection: 'forwards',
    animationDuration: `${iconAnimationTime}s`,
    animationIterationCount: 1,
    animationTimingFunction: 'linearr',
  },
  iconAnimation: {
    animationName: s9.keyframes({
      0: { transform: 'scale(1)', opacity: 1 },
      '50%': { transform: 'scale(0.5)', opacity: 0 },
      '100%': { transform: 'scale(1)', opacity: 1 },
    }),
  },
});

type Icons = IconProps['icon'];

type NonPressablProps = {
  /** indicates whether the chip manages its own pressable state */
  isPressable?: false;
};
interface PressableIcons {
  pressed: Icons;
  unpressed: Icons;
}

type PressableOpts = {
  /** indicates whether the chip manages its own pressable state */
  isPressable: true;
  /** indicates whether the chip is pressed */
  isPressed?: boolean;
  /** The icons to display when the chip is pressed or unpressed */
  pressableIcons?: PressableIcons;
};

export type ChipOwnProps = (NonPressablProps | PressableOpts) & {
  /** The visible content of the chip */
  children?: React.ReactNode;
  /** Text that will only be visible to screen readers */
  screenReaderText?: React.ReactNode;
  variant?: 'base' | 'liveBlog';
  /**
   * CSS declarations to be set as inline `style` on the
   * html element.
   *
   * By setting values of CSS Custom Properties based on
   * props or state in the consuming component (where
   * the value of `inlineStyle` is passed), `inlineStyle`
   * can be used as an API contract for setting dynamic
   * values to styles created with `style9.create()`:
   *
   * @example
   * ```ts
   * import s9 from 'style9';
   * const { styleExtend, } = s9.create({
   *   styleExtend: {
   *     color: 'var(--color-based-on-prop)',
   *   },
   * });
   *
   * function MyChip(props) {
   *   const inlineStyle = {
   *     '--color-based-on-prop': props.color,
   *   },
   *
   *   return (
   *    <Chip
   *      styleExtend={[ styleExtend, ]}
   *      inlineStyle={inlineStyle}
   *    />
   *   );
   * }
   * ```
   */
  inlineStyle?: InlineStyles;
  /**
   * An array of `Style`s created by `style9.create()`.
   * WARNING: **_do not_** pass simple CSS-in-JS object.
   * The items in the array must be created with Style9's
   * `create` function.
   * The array can also hold falsy values to assist with
   * conditional inclusion of `Style`s:
   *
   * @example
   * ```ts
   * const { foo, bar, } = s9.create({ foo: { ... }, bar: { ... }, });
   * <Chip styleExtend={[ someCondition && foo, bar, ]} />
   * ```
   */
  styleExtend?: StyleExtend;
};

export const DEFAULT_ELEMENT = 'button';
type DefaultElement = typeof DEFAULT_ELEMENT;
type AllowedElements = 'a' | 'button' | 'span' | 'address';

export type ChipProps<As extends React.ElementType = DefaultElement> = PolymorphicPropsWithRef<
  ChipOwnProps,
  As,
  AllowedElements
>;

/* eslint-disable @typescript-eslint/indent */
const Chip: PolymorphicForwardRefExoticComponent<ChipOwnProps, DefaultElement, AllowedElements> =
  React.forwardRef(
    /* eslint-enable @typescript-eslint/indent */
    function Chip<As extends React.ElementType = DefaultElement>(
      {
        as,
        isPressable = false,
        isPressed = false,
        children = null,
        screenReaderText,
        pressableIcons = { pressed: 'check', unpressed: 'plus' },
        inlineStyle,
        styleExtend = [],
        variant = 'base',
        ...attrs
      }: ChipProps<As>,
      ref: React.ForwardedRef<As>
    ) {
      const [shouldAnimate, setShouldAnimate] = React.useState(false);
      const iconRef = React.useRef<Icons>(
        isPressed
          ? (pressableIcons as PressableIcons).pressed
          : (pressableIcons as PressableIcons).unpressed
      );
      const pressedStateRef = React.useRef(isPressed);

      const isPressStateChanged = pressedStateRef.current !== isPressed;
      if (isPressStateChanged) {
        pressedStateRef.current = isPressed;
        setShouldAnimate(true);
        iconRef.current = isPressed
          ? (pressableIcons as PressableIcons).pressed
          : (pressableIcons as PressableIcons).unpressed;
      }

      const a11yAttrs: { 'aria-pressed'?: boolean } = {};
      if (isPressable) a11yAttrs['aria-pressed'] = isPressed;

      const startIcon = isPressable ? (
        <StartIcon
          iconRef={iconRef}
          shouldAnimate={shouldAnimate}
          setShouldAnimate={setShouldAnimate}
          icons={pressableIcons}
        />
      ) : null;

      const Element: React.ElementType = as || DEFAULT_ELEMENT;

      return (
        <Element
          ref={ref}
          style={inlineStyle}
          {...attrs}
          {...a11yAttrs}
          className={s9(
            c.base,
            isPressable && isPressed && c.isPressed,
            variant === 'liveBlog' && c.liveBlogVariant,
            ...styleExtend
          )}
        >
          <>
            {startIcon}
            {screenReaderText ? <VisuallyHidden>{screenReaderText}</VisuallyHidden> : null}
            {children}
          </>
        </Element>
      );
    }
  );

// This empty Component is only used for Storybook prop inference
export function _Chip(_props: ChipOwnProps): React.ReactNode {
  return null;
}
export default Chip;

const iconOpposite = (icons: { pressed: Icons; unpressed: Icons }, currentIcon: Icons): Icons =>
  currentIcon === icons.pressed ? icons.unpressed : icons.pressed;

interface StartIconProps {
  iconRef: React.MutableRefObject<Icons>;
  shouldAnimate: boolean;
  setShouldAnimate: React.Dispatch<React.SetStateAction<boolean>>;
  icons: PressableIcons;
}
function StartIcon({ iconRef, shouldAnimate, setShouldAnimate, icons }: StartIconProps) {
  const [icon, setIcon] = React.useState<Icons>(iconRef.current);

  if (shouldAnimate) iconRef.current = iconOpposite(icons, icon);

  const handleAnimationStart = React.useCallback(
    (_evt: React.AnimationEvent) => {
      setTimeout(() => {
        setIcon(iconRef.current);
      }, iconAnimationTime * 500);
    },
    [iconRef]
  );

  const handleAnimationEnd = React.useCallback(
    (_evt: React.AnimationEvent) => {
      setShouldAnimate(false);
    },
    [setShouldAnimate]
  );

  return (
    <Icon
      icon={icon}
      focusable={false}
      styleExtend={[c.icon, shouldAnimate && c.iconAnimation]}
      onAnimationEnd={handleAnimationEnd}
      onAnimationStart={handleAnimationStart}
    />
  );
}
